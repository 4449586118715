
import ClientProposalForm from '@/views/content/dashboard/clientForms/ClientProposalForm.vue'
import { createRouter, createWebHistory } from 'vue-router'

const DashboardPage = () => import('../views/content/dashboard/DashboardPage.vue')
const DashboardHome = () => import('../views/content/dashboard/DashboardHome.vue')
// const ClientInvolvedForm = () => import('../views/content/dashboard/clientForms/ClientInvolvedForm.vue')
const CreateClientBasicDetails = () => import('../views/content/dashboard/clientForms/CreateClientBasicDetails.vue')
const EditClientBasicDetails = () => import('../views/content/dashboard/clientForms/EditClientBasicDetails.vue')
const AiSetup = () => import('../views/content/dashboard/setup/AiSetup.vue')
const LoginPage = () => import('../views/auth/LoginPage.vue')

const NotificationsPage = () => import('../views/content/dashboard/notifications/NotificationsPage.vue');

//const PromptsPage = () => import('../views/content/dashboard/prompts/PromptsPage.vue'); // Importa el nuevo componente

const routes = [
  {
    path: '/', name: 'Dashboard', component: DashboardPage,
    meta: { reqAuth: true },
    children: [   

        { path: '/', component: DashboardHome, name: 'home' },
        { path: '/create-client-basic-details', component: CreateClientBasicDetails, name: 'createClientBasicDetails' },
        { path: '/edit-client-basic-details/:clientData', component: EditClientBasicDetails, name: 'editClientBasicDetails',props:true },
        { path: '/client-proposal-form/:formToken', component: ClientProposalForm, name: 'clientProposalForm',props:true },
        { path: '/ai-setup', component: AiSetup, name: 'aiSetup' },

        // NUEVA RUTA: NotificationsPage.vue
        // Esta ruta muestra todas las notificaciones en una página dedicada
        { path: '/notifications', component: NotificationsPage, name: 'notificationsPage' },

        //{ path: '/prompts', component: PromptsPage, name: 'promptsPage' }, // Nueva ruta para Prompts
    ]
  },
//   { path: '/form/:token', component: ClientInvolvedForm, name: 'clientInvolvedForm',
// meta:{reqAuth:false} },

  { path: '/login', name: 'Login', component: LoginPage },
  
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Configuración de navegación global antes de cada cambio de ruta
router.beforeEach((to, from, next) => {

  let token = localStorage.getItem('access_token');
     if (to.path === '/login' && token) {
    // If the user is already logged in and tries to access the login page,
    // redirect them to the default page
    next('/')
  } else if (to.matched.some(record => record.meta.reqAuth)) {
    

    // This route requires authentication.
    if (!token) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }, // Store the intended path
      })
    } else {
      next() // The user is authenticated, proceed to the route
    }
  } else {
    next() // This route does not require authentication
  }

  })

export default router
