//import { createStore } from 'vuex';

const store = {
  state: {
    notifications: [
      "Form Completed! by company ejem1 with code tFsXbg",
    ], // Aquí se almacenarán las notificaciones
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications; // Actualiza las notificaciones en el estado
    },
  },
  actions: {
    updateNotifications({ commit }, notifications) {
      commit('setNotifications', notifications); // Llama a la mutación para actualizar el estado
    },
  },
};

export default store;
